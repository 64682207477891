import React from 'react';
import { Card, CardContent, Typography, TextField, Button, Box } from '@mui/material';

interface LoginFormProps {
  type: 'particulier' | 'entreprise';
}

const LoginForm: React.FC<LoginFormProps> = ({ type }) => {
  return (
    <Card
      sx={{
        backgroundColor: 'white',
        color: '#1C4171',
        borderRadius: '15px',
        padding: 5,
        maxWidth: '400px',
        boxShadow: 3,
        margin: '0 auto',
      }}
    >
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Connexion {type === 'particulier' ? 'Particulier' : 'Entreprise'}
        </Typography>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Mot de passe"
          variant="outlined"
          fullWidth
          margin="normal"
          type="password"
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Se connecter
        </Button>
        <Box mt={2} textAlign="center">
          <Typography variant="body2">
            Mot de passe oublié ? <a href="/forgot-password">Cliquez ici</a>
          </Typography>
          <Typography variant="body2">
            Pas encore de compte ? <a href="/signup">Inscrivez-vous</a>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default LoginForm;
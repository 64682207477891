import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Button, Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SAPE from '../../assets/images/SAPE_logo.jpg';
import LoginIcon from '@mui/icons-material/Login';
import { useLocation } from 'react-router-dom';

const Header: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: '#1C4171',
        height: { xs: '64px', md: '100px' },
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Toolbar>
        {/* Logo */}
        <IconButton edge="start" color="inherit" aria-label="logo" href="/">
          <img src={SAPE} alt="Sapé logo" style={{ width: '60px', height: '60px' }} />
        </IconButton>

        {/* Desktop Menu avec espacement dynamique */}
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'space-evenly',
            marginLeft: '20px',
            marginRight: '20px',
          }}
        >
          <Button
            sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: isActive('/') ? '#F8A9A0' : 'inherit' }}
            color="inherit"
            href="/"
          >
            Accueil
          </Button>
          <Button
            sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: isActive('/particuliers') ? '#F8A9A0' : 'inherit' }}
            color="inherit"
            href="/particuliers"
          >
            Particuliers
          </Button>
          <Button
            sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: isActive('/entreprise') ? '#F8A9A0' : 'inherit' }}
            color="inherit"
            href="/entreprise"
          >
            Entreprise
          </Button>
          <Button
            sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: isActive('/actualites') ? '#F8A9A0' : 'inherit' }}
            color="inherit"
            href="/actualites"
          >
            Actualités
          </Button>
          <Button
            sx={{ fontSize: '1.1rem', fontWeight: 'bold', color: isActive('/contact') ? '#F8A9A0' : 'inherit' }}
            color="inherit"
            href="/contact"
          >
            Contact
          </Button>
        </Box>
        {/* Login Button */}
        <Button href='/connexion' startIcon={<LoginIcon />} color="pinkSAPE1" variant="contained" sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center', textTransform: 'none', width: 190, height: 50 }}>
          SE CONNECTER
        </Button>

        {/* Mobile Menu Icon */}
        <IconButton edge="end" color="inherit" aria-label="menu" sx={{ display: { xs: 'block', md: 'none' } }} onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>

        {/* Drawer pour le Menu sur mobile (à gauche) */}
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          <List sx={{ width: 250 }}>
            <ListItem component="a" href="/" onClick={toggleDrawer(false)}>
              <ListItemText primary="Accueil" sx={{ color: isActive('/') ? '#F8A9A0' : 'inherit' }} />
            </ListItem>
            <ListItem component="a" href="/particuliers" onClick={toggleDrawer(false)}>
              <ListItemText primary="Particuliers" sx={{ color: isActive('/particuliers') ? '#F8A9A0' : 'inherit' }} />
            </ListItem>
            <ListItem component="a" href="/entreprise" onClick={toggleDrawer(false)}>
              <ListItemText primary="Entreprise" sx={{ color: isActive('/entreprise') ? '#F8A9A0' : 'inherit' }} />
            </ListItem>
            <ListItem component="a" href="/actualites" onClick={toggleDrawer(false)}>
              <ListItemText primary="Actualités" sx={{ color: isActive('/actualites') ? '#F8A9A0' : 'inherit' }} />
            </ListItem>
            <ListItem component="a" href="/contact" onClick={toggleDrawer(false)}>
              <ListItemText primary="Contact" sx={{ color: isActive('/contact') ? '#F8A9A0' : 'inherit' }} />
            </ListItem>
            <ListItem component="a" href="/login" onClick={toggleDrawer(false)}>
              <Button href='/connexion' startIcon={<LoginIcon />} color="pinkSAPE1" variant="contained" fullWidth>SE CONNECTER</Button>
            </ListItem>
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

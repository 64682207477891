import React from 'react';
import { Card, CardContent, Typography, Grid2 } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Slide } from 'react-awesome-reveal';

interface LoginSelectionProps {
  onSelect: (type: 'particulier' | 'entreprise') => void;
}

const LoginSelection: React.FC<LoginSelectionProps> = ({ onSelect }) => {
  return (
    <Grid2 container spacing={4} justifyContent="center">
      <Grid2 size={{ xs: 12, md: 5 }}>
        <Slide direction="left" triggerOnce>
          <Card
            onClick={() => onSelect('particulier')}
            sx={{
              backgroundColor: '#3078B9',
              color: 'white',
              borderRadius: '15px',
              padding: 5,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#265C95',
              },
            }}
          >
            <CardContent sx={{ textAlign: 'center' }}>
              <PersonIcon sx={{ fontSize: 50, marginBottom: '10px' }} />
              <Typography variant="h4" mb={2}>Particulier</Typography>
              <Typography variant="body1">
                Connexion pour les utilisateurs individuels
              </Typography>
            </CardContent>
          </Card>
        </Slide>
      </Grid2>

      <Grid2 size={{ xs: 12, md: 5 }}>
        <Slide direction="right" triggerOnce>
          <Card
            onClick={() => onSelect('entreprise')}
            sx={{
              backgroundColor: '#F16262',
              color: 'white',
              borderRadius: '15px',
              padding: 5,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#E84C4C',
              },
            }}
          >
            <CardContent sx={{ textAlign: 'center' }}>
              <StorefrontIcon sx={{ fontSize: 50, marginBottom: '10px' }} />
              <Typography variant="h4" mb={2}>Entreprise</Typography>
              <Typography variant="body1">
                Connexion pour les marques et enseignes
              </Typography>
            </CardContent>
          </Card>
        </Slide>
      </Grid2>
    </Grid2>
  );
};

export default LoginSelection;

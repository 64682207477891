import React from 'react';
import { Box, Typography, Grid2, Card, CardContent } from '@mui/material';
import Carousel from '../../components/Carousel/Carousel';
import { Slide } from 'react-awesome-reveal';

const HomePage: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        overflowX: 'hidden',
      }}
    >
      {/* Carousel */}
      <Carousel />

      {/* Section des chiffres clés */}
      <Box
        sx={{
          padding: '50px 20px',
          backgroundColor: '#265C95',
          textAlign: 'center',
          marginBottom: '50px',
        }}
      >
        <Typography variant="h3" gutterBottom mb={5} color='white'>
          Quelques chiffres qui nous ont amené à SAPÉ :
        </Typography>

        <Grid2 container spacing={4} justifyContent="center">
          <Grid2 size={{ xs: 12, md: 4 }}>
            <Slide direction="up" triggerOnce>
              <Card
                sx={{
                  backgroundColor: '#1C4171',
                  color: 'white',
                  borderRadius: '10px',
                  padding: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '220px',
                }}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography variant="h4" gutterBottom>
                    68% de nos vêtements restent sur leur cintre
                  </Typography>
                  <Typography variant="h6">
                    Nous ne portons que 32 % de notre garde-robe au quotidien.
                  </Typography>
                </CardContent>
              </Card>
            </Slide>
          </Grid2>

          <Grid2 size={{ xs: 12, md: 4 }}>
            <Slide direction="up" delay={200} triggerOnce>
              <Card
                sx={{
                  backgroundColor: '#3078B9',
                  color: 'white',
                  borderRadius: '10px',
                  padding: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '220px',
                }}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography variant="h4" gutterBottom>
                    80% de nos vêtements sont portés une seule fois par an
                  </Typography>
                  <Typography variant="h6">
                    La majorité de nos habits restent ensuite inutilisés toute l'année.
                  </Typography>
                </CardContent>
              </Card>
            </Slide>
          </Grid2>

          <Grid2 size={{ xs: 12, md: 4 }}>
            <Slide direction="up" delay={400} triggerOnce>
              <Card
                sx={{
                  backgroundColor: '#F16262',
                  color: 'white',
                  borderRadius: '10px',
                  padding: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '220px',
                }}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography variant="h4" gutterBottom>
                    73% des vêtements finissent en décharge
                  </Typography>
                  <Typography variant="h6">
                    Une grande partie de notre garde-robe finit à la poubelle, avec des conséquences sur l’environnement.
                  </Typography>
                </CardContent>
              </Card>
            </Slide>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
};

export default HomePage;
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';
import BackgroundImage from '../../assets/images/BackgroundLogingPage.png';
import LoginForm from './LoginForm';
import LoginSelection from './LoginSelection';

const LoginSelectionPage: React.FC = () => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [selectedType, setSelectedType] = useState<'particulier' | 'entreprise' | null>(null);

  const handleCardClick = (type: 'particulier' | 'entreprise') => {
    setSelectedType(type);
    setShowLoginForm(true);
  };

  const animationProps = useSpring({
    transform: showLoginForm ? 'translateX(0%)' : 'translateX(0%)',
    opacity: showLoginForm ? 1 : 0,
    config: { tension: 200, friction: 20 },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '90vh',
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {!showLoginForm && (
        <Typography variant="h3" sx={{ color: 'white', marginBottom: 5 }}>
          Choisissez votre type de connexion :
        </Typography>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 4,
          width: '100%',
          maxWidth: '1200px',
          transition: 'all 0.5s ease-in-out',
        }}
      >
        {!showLoginForm ? (
          <LoginSelection onSelect={handleCardClick} />
        ) : (
          <animated.div style={{ ...animationProps, display: 'flex', justifyContent: 'center', width: '100%' }}>
            {selectedType && <LoginForm type={selectedType} />}
          </animated.div>
        )}
      </Box>
    </Box>
  );
};

export default LoginSelectionPage;

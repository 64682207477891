import { PaletteColorOptions, createTheme } from '@mui/material';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        xxl: true;
        xxxl: true;
    }

    interface Palette {
        // Couleurs de la palette principale de SAPÉ
        pinkSAPE1: Palette;
        pinkSAPE2: Palette;
        pinkSAPE3: Palette;
        pinkSAPE4: Palette;
        pinkSAPE5: Palette;
        blueSAPE1: Palette;
        blueSAPE2: Palette;
        blueSAPE3: Palette;
        blueSAPE4: Palette;
        blueSAPE5: Palette;
        // Couleurs secondaires de SAPÉ
        purpleButton: Palette;
    }

    interface PaletteOptions {
        // Couleurs de la palette principale de SAPÉ
        pinkSAPE1?: PaletteColorOptions;
        pinkSAPE2?: PaletteColorOptions;
        pinkSAPE3?: PaletteColorOptions;
        pinkSAPE4?: PaletteColorOptions;
        pinkSAPE5?: PaletteColorOptions;
        blueSAPE1?: PaletteColorOptions;
        blueSAPE2?: PaletteColorOptions;
        blueSAPE3?: PaletteColorOptions;
        blueSAPE4?: PaletteColorOptions;
        blueSAPE5?: PaletteColorOptions;
        // Couleurs secondaires de SAPÉ
        purpleButton?: PaletteColorOptions;
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        pinkSAPE1: true;
        blueSAPE1: true;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        pinkSAPE1: true;
        pinkSAPE2: true;
        pinkSAPE3: true;
        pinkSAPE4: true;
        pinkSAPE5: true;
        blueSAPE1: true;
        blueSAPE2: true;
        blueSAPE3: true;
        blueSAPE4: true;
        blueSAPE5: true;
        purpleButton: true;
    }
}

export const theme = createTheme({
    palette: {
        pinkSAPE1: {
            main: '#F16262',
            light: '#F8A9A0',
            dark: '#F16262',
            contrastText: '#FFFFFF',
        },
        pinkSAPE2: {
            main: '#F37472',
            light: '#F37472',
            dark: '#F16262',
            contrastText: '#FFFFFF',
        },
        pinkSAPE3: {
            main: '#F58681',
            light: '#F58681',
            dark: '#F16262',
            contrastText: '#FFFFFF',
        },
        pinkSAPE4: {
            main: '#F8A9A0',
            light: '#F8A9A0',
            dark: '#F16262',
            contrastText: '#FFFFFF',
        },
        pinkSAPE5: {
            main: '#FBCCBF',
            light: '#FBCCBF',
            dark: '#F16262',
            contrastText: '#FFFFFF',
        },
        blueSAPE1: {
            main: '#12254D',
            light: '#2B6AA7',
            dark: '#12254D',
            contrastText: '#FFFFFF',
        },
        blueSAPE2: {
            main: '#1C4171',
        },
        blueSAPE3: {
            main: '#265C95',
        },
        blueSAPE4: {
            main: '#2B6AA7',
        },
        blueSAPE5: {
            main: '#3078B9',
        },
        purpleButton: {
            main: '#8217ad',
            light: '#9B59B6',
            dark: '#8217ad',
            contrastText: '#FFFFFF',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1920,
            xxl: 2560,
            xxxl: 3500,
        },
    },
    typography: {
        h2: {
            fontFamily: 'Lato',
            lineHeight: 'normal',
            fontWeight: 'bold',
            // '@media (max-width:1920px)': {
            //     fontSize: '1.5rem',
            // },
            // '@media (min-width:3500px)': {
            //     fontSize: '3.8rem',
            // },
        },
        h3: {
            fontFamily: 'Lato',
            fontWeight: 'bold',
            // '@media (max-width:1920px)': {
            //     fontSize: '1.125rem',
            //     marginLeft: 10,
            // },
            // '@media (min-width:3500px)': {
            //     fontSize: '2.5rem',
            //     marginLeft: 20,
            // }
        },
        h4: {
            fontFamily: 'Lato',
            fontWeight: 'bold',
            // '@media (max-width:1920px)': {
            //     fontSize: '1em',
            // },
            // '@media (min-width:3500px)': {
            //     fontSize: '2em',
            // }
        },
        h5: {
            fontFamily: 'Lato',
            fontWeight: 'bold',
            // '@media (max-width:1920px)': {
            //     fontSize: '0.875em',
            // },
            // '@media (min-width:3500px)': {
            //     fontSize: '1.875em',
            // }
        },
        h6: {
            fontFamily: 'Lato',
            // '@media (max-width:1920px)': {
            //     fontSize: '0.75em',
            // },
            // '@media (min-width:3500px)': {
            //     fontSize: '1.625em',
            // }
        },
        body1: {
            fontFamily: 'Lato',
            // '@media (max-width:1920px)': {
            //     fontSize: '1rem',
            // },
            // '@media (min-width:3500px)': {
            //     fontSize: '2rem',
            // }
        },
        body2: {
            fontFamily: 'Aptos',
            // '@media (max-width:1920px)': {
            //     fontSize: '0.875rem',
            // },
            // '@media (min-width:3500px)': {
            //     fontSize: '1.875rem',
            // }
        },
    },
});
import React from 'react';
import { Typography, Box, Button, Grid2 } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { ArrowForward, ArrowBack } from '@mui/icons-material';
// Import des images
import Slide1 from '../../assets/images/Slide Carousel/Slide_1.png';
import Slide2 from '../../assets/images/Slide Carousel/Slide_2.png';
import Slide3 from '../../assets/images/Slide Carousel/Slide_3.png';
import Slide4 from '../../assets/images/Slide Carousel/Slide_4.png';
import Slide5 from '../../assets/images/Slide Carousel/Slide_5.png';


// Interface props de Presentation
interface Presentation {
    image: string;
    title: string;
    description: string;
    backgroundColor: string;
    buttonColor: 'primary' | 'secondary' | 'pinkSAPE1' | 'pinkSAPE2' | 'pinkSAPE3' | 'pinkSAPE4' | 'pinkSAPE5' | 'purpleButton';
}

// Les données des différentes slides de Presentation
const presentationList: Presentation[] = [
    {
        image: Slide1,
        title: 'Réinventez votre style, simplifiez votre vie',
        description: "SAPÉ est bien plus qu'une simple application de garde-robe virtuelle. Créée par l'entreprise Evoltly, c'est une solution complète qui révolutionne la manière dont vous gérez votre style et vos vêtements au quotidien",
        backgroundColor: '#1C4171',
        buttonColor: 'pinkSAPE1',
    },
    {
        image: Slide2,
        title: 'Un dressing virtuel toujours à portée de main',
        description: "Avec SAPÉ, planifiez facilement vos tenues où que vous soyez. Plus besoin de vous inquiéter devant votre placard, SAPÉ vous accompagne au quotidien pour optimiser votre style.",
        backgroundColor: '#3078B9',
        buttonColor: 'pinkSAPE3',
    },
    {
        image: Slide3,
        title: 'Un geste pour la planète, un geste pour vous',
        description: "SAPÉ encourage une consommation de mode plus responsable en vous aidant à tirer le meilleur parti de vos vêtements. Moins d'achats impulsifs, plus de styles créatifs avec ce que vous avez déjà.",
        backgroundColor: '#1ABC9C',
        buttonColor: 'pinkSAPE2',
    },
    {
        image: Slide4,
        title: 'Personnalisez votre expérience',
        description: "Grâce à notre technologie intelligente, SAPÉ vous recommande des tenues personnalisées en fonction de vos préférences et de votre garde-robe. Chaque utilisateur vit une expérience unique.",
        backgroundColor: '#9B59B6',
        buttonColor: 'purpleButton',
    },
    {
        image: Slide5,
        title: 'Une communauté passionnée de style',
        description: "Partagez vos tenues, découvrez des idées de looks et interagissez avec une communauté de passionnés. SAPÉ vous connecte avec ceux qui partagent votre amour pour la mode.",
        backgroundColor: '#34495E',
        buttonColor: 'pinkSAPE1',
    },
];

const Carousel: React.FC = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: true,
        pauseOnHover: true,
        adaptiveHeight: false,
        // Personnalisation des points Suivi slides
        appendDots: (dots: any) => (
            <div
                style={{
                    paddingBottom: "50px",
                }}
            >
                <ul style={{ margin: "0px", color: "white" }}> {dots} </ul>
            </div>
        ),
        customPaging: (i: number) => (
            <div
                style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: "white",
                }}
            ></div>
        ),
        // Flèches personnalisées
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
    };

    return (
        <Box sx={{ width: '100vw', overflowX: 'hidden' }}>
            <Slider {...settings}>
                {presentationList.map((slide, index) => (
                    <Box sx={{ flexGrow: 1 }} key={index}>
                        <Grid2
                            key={index}
                            container
                            sx={{
                                height: '80vh',
                                backgroundColor: slide.backgroundColor,
                            }}
                            alignItems="center"
                            spacing={2}
                            direction={{ xs: 'column', md: 'row' }}
                        >
                            {/* Image à gauche */}
                            <Grid2 size={{ xs: 12, md: 6 }} display="flex" justifyContent="center" alignItems="center">
                                <img
                                    src={slide.image}
                                    alt={slide.title}
                                    style={{ width: '100%', maxWidth: '600px', borderRadius: '8px' }}
                                />
                            </Grid2>

                            {/* Texte à droite */}
                            <Grid2 size={{ xs: 12, md: 4 }} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" padding={0.5} color="white">
                                <Typography variant="h3" mb={5} gutterBottom>{slide.title}</Typography>
                                <Typography variant="body1" align='justify' sx={{ marginBottom: 20, fontSize: '1.2rem' }}>
                                    {slide.description}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color={slide.buttonColor as 'primary' | 'secondary' | 'pinkSAPE1' | 'pinkSAPE2' | 'pinkSAPE3' | 'pinkSAPE4' | 'pinkSAPE5' | 'purpleButton'}
                                >
                                    En savoir plus
                                </Button>
                            </Grid2>
                            <Grid2 size={{ xs: 0, md: 2 }} />
                        </Grid2>
                    </Box>
                ))}
            </Slider>
        </Box>
    );
};

// Flèches personnalisées
const CustomNextArrow: React.FC = (props: any) => {
    const { onClick } = props;
    return (
        <div
            style={{
                right: "50px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 1,
                display: "flex",
                background: "#fff",
                borderRadius: "50%",
                padding: "10px",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                position: "absolute",
            }}
            onClick={onClick}
        >
            <ArrowForward style={{ fontSize: '65px', color: '#1C4171' }} />
        </div>
    );
};

const CustomPrevArrow: React.FC = (props: any) => {
    const { onClick } = props;
    return (
        <div
            style={{
                left: "50px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 1,
                display: "flex",
                background: "#fff",
                borderRadius: "50%",
                padding: "10px",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                position: "absolute",
            }}
            onClick={onClick}
        >
            <ArrowBack style={{ fontSize: '65px', color: '#1C4171' }} />
        </div>
    );
};

export default Carousel;
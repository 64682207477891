import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Admin from "./pages/Admin/Admin";
import CGU from "./pages/CGU/CGU";
import Contact from "./pages/Contact/Contact";
import FAQ from "./pages/FAQ/FAQ";
import LegalInformations from "./pages/Mentions_legales/LegalInformations";
import MyAccount from "./pages/Mon_compte/MyAccount";
import Politique from "./pages/Politique/Politique";
import AboutUs from "./pages/Qui_somme_nous/AboutUs";
import LoginSelectionPage from './pages/Signup_login/LoginSelectionPage';
import HomePage from './pages/Accueil/HomePage';
import News from './pages/Actualites/News';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './assets/theme';
import './App.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <div className="app-container">
        <Header />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<HomePage />}></Route>
            <Route path="/actualites" element={<News />}></Route>
            <Route path="/admin" element={<Admin />}></Route>
            <Route path="/cgu" element={<CGU />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/faq" element={<FAQ />}></Route>
            <Route path="/mentions-legales" element={<LegalInformations />}></Route>
            <Route path="/mon-compte" element={<MyAccount />}></Route>
            <Route path="/politique" element={<Politique />}></Route>
            <Route path="/qui-sommes-nous" element={<AboutUs />}></Route>
            <Route path="/connexion" element={<LoginSelectionPage />}></Route>
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

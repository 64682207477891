import React from 'react';
import './Footer.css';
import SAPE from '../../assets/images/SAPE_logo.jpg';
import Instagram from '../../assets/images/Instagram.png';
import AppStore from '../../assets/images/AppStore.png';
import GooglePlay from '../../assets/images/GooglePlay.png';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section">
                <a href="/"><img src={SAPE} alt="Sapé logo" className="logo" /></a>
                </div>
                <div className="footer-section">
                    <h4>A PROPOS DE NOUS</h4>
                    <ul>
                        <li><a href="/qui-sommes-nous">Qui sommes nous ?</a></li>
                        <li><a href="/particuliers">Particuliers</a></li>
                        <li><a href="/entreprises">Entreprises</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>SUPPORT</h4>
                    <ul>
                        <li><a href="/actualites">Actualités</a></li>
                        <li><a href="/faq">FAQ</a></li>
                        <li><a href="/contact">Contactez-nous</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>INFORMATIONS LEGALES</h4>
                    <ul>
                        <li><a href="/mentions-legales">Mentions légales</a></li>
                        <li><a href="/cgu">Conditions générales d'utilisation</a></li>
                        <li><a href="/politique">Politique de confidentialité</a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-item social-media">
                    <span>Retrouvez-nous sur :</span>
                    <a href="https://www.instagram.com/sapeoff_/?hl=fr"><img src={Instagram} alt="Instagram" /></a>
                </div>
                <div className="footer-item mobile-apps">
                    <span>Votre Garde Robe dans votre poche</span>
                    <a href="https://www.apple.com/fr/app-store/"><img src={AppStore} alt="App Store" /></a>
                    <a href="https://play.google.com/store/games"><img src={GooglePlay} alt="Google Play" /></a>
                </div>
                <div className="footer-item footer-copyright">
                    <span>© Evolty 2024. All Rights Reserved.</span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;